@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    font-family: "Open Sans";
    border-radius: 0px;
    box-sizing: border-box;
}

:root {
    --gray-mail: rgb(230,230,230);
    --accent-one: rgba(11, 115, 254, 255);
    --accent-two: rgba(41, 145, 274, 1);
    --shadow-one: 0px 10px 20px rgba(30, 24, 24, 0.1);
    --gray-half: rgba(251, 251, 251, 255);
    --gray-one: rgba(249, 249, 249, 255);
    --gray-one-half: rgba(244, 244, 244, 255);
    --gray-two: rgba(236, 236, 236, 255);
    --gray-three: rgba(232, 232, 232, 255);
    --gray-five: rgba(220, 220, 220, 255);
    --gray-six: rgba(140, 140, 140, 255);
    --gray-four: rgba(192, 192, 192, 255);
    --almost-black: rgb(60,60,60);
    --text-color-one: white;
    --border-radius-one: 3px;
    --border-bottom-color: rgba(210,210,210,255);
    --border-bottom-color-2: rgba(150,150,150,255);
    --nav-scale: 60px;
    --transition-nav: .7s cubic-bezier(0.4, 0, 0.2, 1);
    --text-color-course: black;
    --font-size-one: 14px;
    --font-size-two: 16px;
    --vs-background: rgba(31, 31, 31, 255);
    --vs-border: rgba(110, 110, 110, 255);
    --vs-icon: rgba(204, 204, 204, 1);
    --dark-background: rgb(10,10,10);
    --correct: rgba(1, 164, 33, 255);
    --incorrect: rgba(224, 6, 31, 255);
    --opacity-level: .3;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    width: 100%;
    height: var(--nav-scale);
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: var(--gray-one);
    font-size: var(--font-size-one);
    border-bottom: 1px solid var(--border-bottom-color);
    color: var(--blue-eight);
    box-sizing: border-box;
    z-index: 1000;
}

.icon {
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon.width {
    width: 100%;
}

.basic-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-icon {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--nav-scale);
}

.opacity {
    opacity: 0%;
}

.add-course  {
    height: 35px;
    padding-left: 20px;
    padding-right: 25px;
    background-color: var(--accent-one);
    color: var(--text-color-one);
    border-radius: var(--border-radius-one);
    font-size: var(--font-size-one);
    cursor: pointer;
    column-gap: 3px;
    font-weight: 600;
    text-align: center;
    text-wrap: nowrap;
    user-select: none;
    box-sizing: content-box;
    transition: width .7s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
}

.ungraded-next-previous {
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    width: 190px;
    background-color: var(--gray-one);
    color: var(--accent-one);
    border-radius: var(--border-radius-one);
    font-size: var(--font-size-one);
    cursor: pointer;
    column-gap: 3px;
    font-weight: 600;
    text-wrap: nowrap;
    user-select: none;
    box-sizing: border-box;
    transition: width .7s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--accent-one);
    transition: .3s ease;
    overflow: hidden;
}

.ungraded-next-previous:hover {
    background-color: var(--accent-one);
    color: var(--text-color-one);
    border-radius: var(--border-radius-one);
}

.next-previous {
    height: 35px;
    width: 80px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: var(--accent-one);
    color: var(--text-color-one);
    border-radius: var(--border-radius-one);
    font-size: var(--font-size-one);
    cursor: pointer;
    column-gap: 3px;
    font-weight: 600;
    text-wrap: nowrap;
    user-select: none;
    box-sizing: content-box;
    transition: width .7s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.next-previous:hover {
    background-color: var(--accent-two);
}

.add-course.assessment {
    padding-left: 35px;
    padding-right: 40px;
}

.add-course.two {
    padding-left: 20px;
    padding-right: 25px;
}

.add-course.open {
    width: 10px;
}

.add-course.closed {
    width: 100px;
}

.add-course:hover {
    background-color: var(--accent-two);
}

.icon-2-responsive, .icon-3, .icon-2 {
    position: relative;
    width: var(--nav-scale);
    height: var(--nav-scale);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--border-bottom-color);
    transition: var(--transition-nav);
    box-sizing: border-box;
}

.icon-2-responsive {
    width: 240px;
}

.icon-2-responsive.open {
    width: 190px;
}

.icon-3-container {
    width: var(--nav-scale);
    height: var(--nav-scale);
    cursor: pointer;
}

.icon-3 {
    cursor: pointer;
    width: calc(var(--nav-scale) / 2);
    height: calc(var(--nav-scale) / 2);
    border-right: none;
    border-radius: calc(var(--nav-scale) / 2);
    border: 2px solid var(--border-bottom-color-2);
    min-width: calc(var(--nav-scale) / 2);
}

.icon-3-3, .icon-3-2 {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: calc(var(--nav-scale) / 2);
    border: 2px solid var(--border-bottom-color-2);
}

.icon-3-3 {
    border: none;
    border: 2px solid transparent;
}

.side-nav-container-open, .side-nav-container {
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: var(--nav-scale);
    border-right: 1px solid var(--border-bottom-color);
    z-index: 900;
    background-color: var(--gray-one);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: var(--nav-scale);
    transition: var(--transition-nav);
    overflow: auto;
    box-sizing: border-box;
}

.side-nav-container-open {
    width: 240px;
}

.side-nav-container-open.open {
    width: 190px;
}

.nav-icon-container {
    width: 100%;
    height: 100%;
    padding-top: var(--nav-scale);
}

.block {
    position: absolute;
    top: var(--nav-scale);
    right: 0%;
    width: var(--nav-scale);
    height: var(--nav-scale);
}

.nav-icon-with-back {
    position: absolute;
    top: var(--nav-scale);
    right: 0%;
    width: var(--nav-scale);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.block-2 {
    position: absolute;
    top: 0%;
    left: 0%;
    width: var(--nav-scale);
    height: var(--nav-scale);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.courses-container {
    width: 100%;
    position: relative;
}

.header {
    width: 100%;
    height: 250px;
    background-color: var(--accent-one);
    position: absolute;
    top: 0%;
    left: 0%;
}

.single-course-container, .courses-container-2 {
    position: static;
    margin-top: 90px;
    margin-left: 90px;
    margin-right: 90px;
    padding-bottom: 130px;
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.single-course-container.viewer {
    margin-right: 0px;
}

.single-course-container.height {
    height: calc(100vh - 60px);
    padding-bottom: 0px;
    justify-content: space-between;
    row-gap: 0px;
    min-height: 200px;
    box-sizing: border-box;
}

.single-course-container.small {
    height: auto;
}

.single-course-container {
    justify-content: flex-start;
    row-gap: 0px;
    height: auto;
}

.your-course, .your-courses {
    font-size: 25px;
    font-weight: 500;
    color: var(--text-color-one);
    width: 100%;
    text-align: left;
}

.your-course {
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.course-metadata-2, .course-metadata {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.course-metadata {
    column-gap: 35px;
    max-height: 130px;
    position: relative;
}

.course-metadata-2 {
    color: var(--text-color-one);
    font-size: var(--font-size-one);
    font-weight: 400;
}

.semester-period-2, .semester-period {
    color: var(--text-color-one);
    font-size: var(--font-size-two);
    font-weight: 500;
    margin-bottom: -10px;
    width: 100%;
    text-align: left;
}

.semester-period-2 {
    margin-top: 20px;
    color: var(--almost-black);
}

.add-course-card, .course-card-container {
    width: 28%;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: .1s ease;
    font-size: var(--font-size-two);
    border: none;
}

.course-card-container:hover {
    text-decoration: underline;
}

.add-course-card {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--almost-black);
    font-weight: 600;
    background-color: var(--gray-two);
}

.add-course-card:hover {
    background-color: var(--gray-three);
}

.courses-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    width: 100%;
}

.course-card-bottom {
    width: 100%;
    color: var(--almost-black);
    font-size: var(--font-size-one);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
    padding-top: 15px;
}

.course-card-bottom-background {
    background-color: var(--gray-two);
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.course-card-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    background-color: var(--gray-two);
}

.course-card-description, .course-card-name {
    width: 100%;
    color: var(--almost-black);
    text-align: left;
    font-weight: 700;
    font-size: var(--font-size-two);
    text-wrap: nowrap;
    overflow: auto;
}

.course-card-description {
    font-size: var(--font-size-one);
    font-weight: 500;
}

.course-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.arrow-container-4-2, .arrow-container-4, .arrow-container-3, .arrow-container-2-2, .arrow-container-2, .arrow-container-1-2, .arrow-container-1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-two);
    font-weight: 600;
    text-wrap: nowrap;
    column-gap: 3px;
}

.arrow-container-4-2 {
    width: 35%;
    justify-content: flex-end;
}

.arrow-container-4-2.fifty {
    width: 50%;
}

.arrow-container-4-2.fifty {
    width: 50%;
}

.arrow-container-4 {
    width: 25%;
    justify-content: flex-end;
}

.arrow-container-3 {
    width: 30%;
}

.status-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-two);
    font-weight: 600;
    text-wrap: nowrap;
    column-gap: 3px;
    cursor: pointer;
    user-select: none;
}

.arrow-container-2-2 {
    width: 25%;           
}

.arrow-container-2 {
    width: 30%;
}

.arrow-container-1-2 {
    width: 50%;
}

.arrow-container-1 {
    width: 40%;
}

.arrow-container-1.fifty {
    width: 50%;
}

.assessname {
    color: var(--almost-black);
}

.assessname:hover {
    text-decoration: underline;
    color: var(--accent-one);
}

.exams-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.exam-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray-four);
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.color {
    color: var(--text-color-course);
    font-size: var(--font-size-one);
    font-weight: 400;
}

.special {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.course-dropdown-container-custom {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 16px;
    position: relative;
    display: flex;
}

.course-dropdown-custom {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    position: absolute;
}

.course-dropdown-custom.space {
    width: 16px;
    height: 16px;
    border-radius: 0px;
    position: static;
}

.materials-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.no-under {
    text-decoration: none;
}

.bar {
    width: 1px;
    height: 100%;
    background-color: var(--text-color-one);
}

.semester-nav-info {
    width: 100%;
    padding: 20px;
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 15px;
    padding-bottom: 30px;
}

.text-side-nav {
    font-size: var(--font-size-one);
}

.text-side-nav.container {
    display: flex;
    width: 100%;
    height: 42px;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
}

.side-nav-info-header-special, .side-nav-info-header {
    font-weight: 700;
    font-size: var(--font-size-two);
    height: auto;
    position: relative;
}

.side-nav-info-header-special.open {
    font-size: var(--font-size-one);
}

.side-nav-info-header.title {
    font-size: 25px;
}

.meta-info-side {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: auto;
    max-height: 190px;
    min-height: 35px;
}

.meta-info-side-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    row-gap: 12px;
    box-sizing: border-box;
}

.instructor-name {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    text-wrap: nowrap;
    font-size: var(--font-size-one);
}

.color-2 {
    background-color: black;
}

.wrap {
    text-wrap: wrap;
}

.wrap:hover {
    text-decoration: underline;
}

.font {
    font-size: var(--font-size-two);
    font-weight: 500;
}

.font.two {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
}

.font.three {
    font-size: var(--font-size-one);
}

.font.three {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
}

.font.four {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 5px;
}

.assessment-name-container {
    position: relative;
    cursor: pointer;
    text-wrap: wrap;
    word-break: break-word;
    padding-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    color: var(--text-color-course);
    text-decoration: underline;
}

.course-name {
    font-size: var(--font-size-one);
    text-wrap: wrap;
    word-break: break-word;
    font-size: max(16px, calc(1vw + 1vh + 1vmin));
}

.person-workspace-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-header-container {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: var(--text-color-one)
}

.person-edit-container {
    display: flex;
    text-wrap: nowrap;
    overflow: auto;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    column-gap: 20px;
    font-size: var(--font-size-two);
    font-weight: 600;
}

.account-settings-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 20px;
}

.required {
    color: red;
    font-weight: 600;
}

.required-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3px;
    user-select: none;
    font-size: var(--font-size-one);
}

.input-field {
    max-width: 700px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.input-field.padding {
    margin-bottom: 70px;
}

.input-field.auto {
    height: auto;
}

.top-input {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3px;
    font-size: var(--font-size-one);
    font-weight: 600;
    max-width: 700px;
    text-wrap: nowrap;
    overflow: auto;
}

.top-input.bottom {
    border-bottom: 2px solid var(--gray-four);
}

.bottom-input {
    width: 100%;
    max-width: 700px;
    border: none;
    border-bottom: 2px solid var(--gray-four);
    box-sizing: border-box;
    padding: 0px;
    font-size: var(--font-size-one);
}

.student-id {
    border: none;
    border-bottom: 2px solid var(--gray-four);
    outline: none;
}

.bottom-input-2 {
    width: 100%;
    height: 80px;
    border: 2px solid var(--gray-three);
    border-radius: 5px;
    padding: 0px;
    font-size: var(--font-size-one);
    padding: 2px;
    text-align: left;
    vertical-align: top;
    box-sizing: border-box;
}

.year-and-term-container {
    width: 100%;
    display: flex;
    column-gap: 5%;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.year-term {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 10px;
    font-size: var(--font-size-one);
    width: 47.5%;
    user-select: none;
}

.input-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: var(--gray-two);
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    outline: none;
}

.bottom-input.email {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: var(--font-size-one);
}

.input-container-pad {
    width: 91%;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    display: flex;
}

.bottom-input:focus {
    outline: none;
    border-bottom: 2px solid var(--accent-one);
}

.bottom-input-2:focus {
    border: 2px solid var(--accent-one);
    outline: none;
}

.primary-button-container {
    width: 140px;
    height: 25px;
    background-color: var(--accent-one);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    padding-left: 5px;
    padding-right: 5px;
    color: var(--text-color-one);
    font-size: var(--font-size-one);
    text-wrap: nowrap;
}

.save-changes-container {
    width: 100%;
    max-width: 700px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    flex-wrap: nowrap;
}

.save-changes-2, .save-changes, .cancel {
    width: 140px;
    max-width: 100%;
    height: 100%;
    background-color: var(--text-color-one);
    border: 2px solid var(--accent-one);
    color: var(--accent-one);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    transition: .2s cubic-bezier(0.4, 0, 0.2, 1);
    user-select: none;
    font-size: var(--font-size-one);
    border-radius: 50px;
    text-align: center;
}

.cancel.extra {
    width: 160px;
}

.save-changes.extra {
    width: 160px;
}

.save-changes-2 {
    background-color: var(--accent-one);
    border: 2px solid var(--accent-one);
    color: var(--text-color-one);
    width: 160px;
    height: 50px;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: content-box;
    font-size: var(--font-size-one);
}

.cancel:hover {
    background-color: var(--accent-one);
    border: 2px solid var(--accent-one);
    color: var(--text-color-one);
}

.save-changes {
    background-color: var(--accent-one);
    border: 2px solid var(--accent-one);
    color: var(--text-color-one);
}

.save-changes-2:hover, .save-changes:hover {
    background-color: var(--text-color-one);
    border: 2px solid var(--accent-one);
    color: var(--accent-one);
}

.eye {
    position: absolute;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
}

.add-email-container {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    row-gap: 0px;
    font-size: var(--font-size-one);
}

.add-email-button {
    display: flex;
    column-gap: 0px;
    color: var(--accent-one);
    font-weight: 600;
    cursor: pointer;
    text-wrap: nowrap;
}

.add-email-button:hover {
    text-decoration: underline;
}

.gear {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.icon-new {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    margin-right: 5px;
}

.paint-add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 10px;
}

.paint-brush {
    cursor: pointer;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.side-nav-color-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.black-back {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,255);
    opacity: 50%;
    z-index: 10000;
}

.add-a-course-form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--text-color-one);
    max-width: 95vw; /* Maximum width of 95% of the viewport width */
    max-height: 95vh; /* Maximum height of 95% of the viewport height */
    width: 500px; /* Default width of 500px */
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto; /* Allows scrolling if content overflows */
    padding: 20px;
    height: auto;
}

.add-a-course-form-container.instructor {
    height: 300px;
}

.add-a-course-form-container.assess {
    height: 585px;
}

/* Media query to handle when the viewport is too narrow */
@media (max-width: 500px) {
    .add-a-course-form-container {
        width: 95vw; /* Width adjusts to 95% of the viewport width */
    }
}

.add-a-course-form-content-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.add-course-title {
    color: var(--almost-black);
    font-size: 25px;
    font-weight: 700;
}

.banner-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    column-gap: 10px;
    overflow-x: auto;
    text-wrap: nowrap;
}

.form-width {
    width: 100%;
}

.banner {
    width: 100%;
    font-size: var(--font-size-one);
    background-color: rgba(245, 247, 249, 255);
    border-radius: 5px;
    color: var(--almost-black);
    border: 1px solid var(--gray-mail);
    font-weight: 600;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
}

.logout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    font-size: var(--font-size-one);
    cursor: pointer;
    color: var(--accent-one);
}

.logout:hover {
    text-decoration: underline;
}

.no-assignments-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.no-assignments {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--almost-black);
    row-gap: 20px;
    font-size: var(--font-size-one);
    font-weight: 500;
    text-align: center;
}

.assessment-page-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--text-color-one);
    font-size: 25px;
    font-weight: 500;
}

.assessment-page-bottom {
    color: var(--text-color-one);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    font-size: var(--font-size-two);
    font-weight: 600;
}

.assessment-page-content {
    width: 100%;
    height: 100%;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 5%;
    row-gap: 20px;
}

.assessment-page-content.min {
    column-gap: 0px;
    row-gap: 0px;
}

.assessment-page-content.small {
    flex-direction: column;
}

.left-info {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 150;
    position: relative;
    padding-bottom: 45px;
}

.left-info.fullscreen {
    padding-bottom: 0px;
    width: 40%;
    min-width: 200px;
}

.left-info.small {
    width: 100%;
    height: fit-content;
}

.right-info {
    width: 65%;
    height: 100%;
    color: var(--almost-black);
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    font-size: var(--font-size-two);
    background-color: var(--text-color-one);
    text-align: center;
    padding: 0px;
}

.right-info.small {
    width: 100%;
    height: 250px;
}

.left-info-content {
    background-color: var(--gray-two);
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    row-gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    row-gap: 17px;
}

.text-side-nav.display {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3px;
}

.text-side-nav.display-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    font-size: var(--font-size-one);
    font-weight: 400;
}

.pdf-content-container {
    width: 100%;
    height: 100%;
    background-color: var(--text-color-one);
    z-index: 150;
}

.pdf-content-container.fullscreen {
    width: 100%;
}

.pdf-content-container.small {
    width: 100%;
    height: 275px;
}

.pdf-content-container.false {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    row-gap: 10px;
    border: 2px solid var(--gray-two);
    font-size: var(--font-size-one);
    text-align: center;
}

.pdf-upload-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.pdf-choose {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
}

.metric-select {
    background-color: var(--gray-three);
    border-bottom: 2px solid var(--gray-four);
    border-radius: 5px;
    font-size: var(--font-size-one);
    cursor: pointer;
}

.metric-select.question {
    outline: none;
    border: none;
    border-bottom: 2px solid var(--gray-four);
}

.metric-select.question-2 {
    padding: 4px;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    column-gap: 4px;
    justify-content: center;
    align-items: center;
}

.center-chevron {
    height: 100%;
    display: flex;
    align-items: center;
}
 
.metric-select.feedback {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--gray-five);
}

.roster-meta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-wrap: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
}

.roster-meta-title {
    font-size: var(--font-size-two);
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 3px;
    font-weight: 600;
    text-wrap: nowrap;
    display: flex;
    column-gap: 6px;
}

.color.one, .roster-meta-title.one {
    width: 50%;
}

.name-submission {
    text-decoration: underline;
    cursor: pointer;
    color: var(--almost-black);
    display: flex;
    align-items: center;
}

.name-submission:hover {
    color: var(--accent-one);
}

.color.two, .roster-meta-title.two {
    width: 25%;
}

.color.three, .roster-meta-title.three {
    width: 20%;
    justify-content: flex-end;
    text-align: right;
}

.roster-student {
    overflow: auto;
}

.extra-time {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.extra-time-2 {
    cursor: pointer;
    background-color: var(--gray-two);
    border: 1px solid var(--gray-four);
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.name-student {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.name-student-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    cursor: pointer;
}

.clipboard {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grade-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    align-items: center;
    font-size: 18px;
    color: var(--almost-black);
    font-weight: 600;
}

.grade-box-enter {
    width: 85px;
    outline: none;
    border: none;
    font-size: 18px;
    border-bottom: 3px solid var(--gray-four);
    color: var(--almost-black);
    font-weight: 600;
    background-color: transparent;
}

.grade-box-enter:focus {
    border-bottom: 3px solid var(--accent-one);
}

.submit {
    border-radius: 3px;
    width: 48%;
    height: 20px;
    border: 1px solid var(--accent-one);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-one);
    cursor: pointer;
    color: var(--text-color-one);
    background-color: var(--accent-one);
}


.regenerate {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    width: 100px;
}

.box-plot-container-submission {
    width: 100%;
    display: flex;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    padding: 15px;
}

.submission-item {
    font-size: var(--font-size-two);
    color: var(--text-color-course);
    width: 100%;
    text-align: left;
    font-weight: 600;
}

.handle-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.entry-exit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: var(--gray-one);
    border-radius: 3px;
    border: 2px solid var(--gray-four);
    font-size: var(--font-size-two);
}

.codes-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 160px;
}

.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
width: 8px;
height: 8px;
margin: 0 4px;
background-color: #333;
border-radius: 50%;
animation: blink 1.4s infinite both;
}

.dot.white {
    background-color: white;
}

.dot:nth-child(1) {
animation-delay: 0s;
}

.dot:nth-child(2) {
animation-delay: 0.2s;
}

.dot:nth-child(3) {
animation-delay: 0.4s;
}

@keyframes blink {
0%, 80%, 100% {
    opacity: 0;
}
40% {
    opacity: 1;
}
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.points-viewing-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.back-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 120px;
    right: 0px;
    width: 60px;
    cursor: pointer;
    position: absolute;
    top: -20px;
    left: -10px;
}

.back {
    color: var(--text-color-one);
    font-size: 12px;
    font-weight: 500;
}

.lockdown {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 10px;
    font-size: var(--font-size-one);
    color: var(--almost-black);
    flex-wrap: nowrap;
    background-color: var(--gray-two);;
    height: 45px;
    border: 1px solid var(--gray-four);
    border-left: none;
    box-sizing: border-box;
}

.search-container {
    width: 100%;
    position: relative;
}

.search-input {
    width: 100%;
    background-color: transparent;
    border: none;
    height: 25px;
    font-size: var(--font-size-one);
    color: var(--text-color-one);
    outline: none;
    border-bottom: 1px solid var(--text-color-one);
    padding-left: 18px;
}

.search-input::placeholder {
    color: var(--text-color-one);
}

.search-icon {
    position: absolute;
    left: 0px;
    bottom: 9px;
}

.search-holder {
    position: absolute;
    top: 0px;
    right: 0px;
}

.search-holder-bottom {
    position: absolute;
    top: 70px;
    left: 0px;
}

.required-star {
    color: red;
}

.names-dropdown {
    position: absolute;
    z-index: 1000;
    bottom: 0px;
    left: 0px;
    transform: translate(0px, 100%);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(245, 247, 249, 255);
    flex-direction: column;
    overflow: auto;
    max-height: 150px;
    border: 2px solid rgb(230,230,230);
    box-sizing: border-box;
}

.names-dropdown-item {
    width: 100%;
    border-top: 1px solid rgb(230,230,230);
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.names-dropdown-item:hover {
    background-color: var(--gray-three);
}

.feedback-window-container {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: var(--gray-one);
    padding: 10px;
    border: 1px solid var(--gray-four);
    outline: none;
    font-size: var(--font-size-one);
    padding-bottom: 30px;
    box-sizing: border-box;
    overflow: hidden; /* Disable scrolling */
    resize: none; /* Prevent manual resizing */
}

.save-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: var(--accent-one);
    color: var(--text-color-one);
    font-weight: 400;
    column-gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: var(--font-size-one);
    text-wrap: nowrap;
}

.save-status.delete {
    font-size: var(--font-size-one);
}

.save-status:hover {
    background-color: var(--accent-two);
}

.form-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.chevron-container {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0px, -50%);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(11, 115, 254, .7);
    transition: .3s ease;
}

.chevron-container-2 {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0px, -50%);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(11, 115, 254, .7);
    transition: .3s ease;
}

.chevron-container.hover:hover, .chevron-container-2.hover:hover {
    background-color: var(--accent-one);
    cursor: pointer;
}

.form-id {
    text-decoration: underline;
    color: var(--almost-black);
    cursor: pointer;
}

.form-id:hover {
    color: var(--accent-one);
}

.collapsed {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;
}

.collapsed.hover:hover {
    background-color: var(--gray-three);
}

.collapsed.current {
    background-color: var(--accent-one);
    color: white;
}

.open-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-right: 10px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    text-wrap: nowrap;
    font-size: var(--font-size-one);
}

.open-nav.hover:hover {
    background-color: var(--gray-three);
}

.open-nav.current {
    background-color: var(--accent-one);
    color: white;
}

.custom-color {
    width: 40px;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    color: none;
    padding: 0px;
}

.collapsed-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 5px;
}

.overflow-name {
    text-wrap: nowrap;
    overflow: auto;
    max-width: max(calc(100% - 500px));
}

.overflow-name-stat {
    text-wrap: nowrap;
    overflow: auto;
    max-width: max(calc(100% - 100px));
}

.overflow-name-small {
    text-wrap: nowrap;
    overflow: auto;
    max-width: max(calc(100% - 200px));
}

.overflow-name-small-small {
    text-wrap: nowrap;
    overflow: auto;
    max-width: 100%;
}

.overflow-code {
    text-wrap: nowrap;
    font-size: var(--font-size-one);
}

.assessment-name {
    width: 100% - 25px;
    text-wrap: nowrap;
    overflow: auto;
}

.student-name-overflow {
    width: 100%;
    text-wrap: nowrap;
    overflow: auto;
}

.underline:hover {
    text-decoration: underline;
}

.little-x {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--accent-one);
    border-radius: 3px;
    color: var(--text-color-one);
    position: absolute;
    top: 3px;
    right: 0px;
    z-index: 1000;
    cursor: pointer;
}

.little-x:hover {
    background-color: var(--accent-two);
}

.full {
    margin-top: 60px;
    margin-left: 60px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: 100%;
}

.pdf-container-non-multi {
    width: 100%;
    height: 100%;
}

.double-chev {
    color: var(--accent-one);
    border: none;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    transition: 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -1px;
}

.double-chev:hover {
    background-color: var(--gray-five);
}

.next-container {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.flex-code {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0px;
}

.copy {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.info-container {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1.5px solid var(--almost-black);
}

.info-container.dockerfile {
    color: var(--accent-one);
    border: 1.5px solid var(--accent-one);
}

.info-hover {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 30px;
    height: 30px;
    background-color: var(--accent-one);
    border-radius: 30px;
    color: var(--text-color-one);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 2px 20px rgba(0,0,0, .5);
}

.keybind-hints {
    position: absolute;
    bottom: 0px;
    left: 70px;
    width: 270px;
    transition: .3s ease;
    display: flex;
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    row-gap: 15px;
    flex-direction: column;
    background-color: rgba(0,0,0,.93);
    z-index: 20000;
}

.arrow {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 10px;
    transition: .3s ease;
    left: 62.5px;
    transform: rotate(45deg);
    background-color: rgba(0,0,0,.93);
}

.keybind {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.keybind-text {
    font-size: var(--font-size-one);
    color: var(--text-color-one);
    font-weight: 600;
}

.remaining {
    width: 100%;
    height: 270px;
    position: relative;
    box-sizing: border-box;
}

.remaining-bar {
    width: fit-content;
    height: fit-content;
    padding: 4px;
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    color: var(--almost-black);
    background-color: var(--gray-two);
    border: 1px solid var(--gray-four);
    border-radius: 10px 0 10px 0;
    box-sizing: border-box;
}

.graded-percent-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    align-items: center;
}

.graded-percent {
    width: 100%;
    height: 10px;
    border: 1px solid var(--gray-three);
    background-color: var(--gray-two);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.graded-percent-bar {
    height: 10px;
    background-color: rgba(1, 164, 33, 255);
    box-sizing: border-box;
}

.graded-percent-box {
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.feedback-row {
    width: 100%;
    height: 40px;
    min-height: 40px;
    border-top: 1px solid var(--gray-four);
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    box-sizing: border-box;
    text-wrap: nowrap;
    cursor: pointer;
    overflow: auto;
    font-size: 12px;
    font-weight: 400;
    color: var(--almost-black);
    background-color: var(--gray-two);
}

.feedback-loading {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-box {
    display: flex;
    width: auto;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.editor-tree-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.file-visualizer-open {
    width: 240px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 10px;
    min-height: 300px;
}

.editor-container {
    width: calc(100% - 240px);
    height: 100%;
    border-left: none;
    background-color: inherit;
    padding-top: 30px;
    position: relative;
}

.document-icon {
    width: 40px;
    height: 40px;
    padding-top: 5px;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.visualizer-container {
    height: 100%;
    width: 100%;
}

.node {
    color: inherit;
    font-size: var(--font-size-one);
    cursor: pointer;
    text-wrap: nowrap;
}

.node-hover:hover {
    text-decoration: underline;
}

.node-current {
    border-bottom: 1px solid var(--vs-icon);
}

.loading-zip-container {
    width: 100%;
    height: calc(100vh - var(--nav-scale));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
    color: var(--almost-black);
}

.location-settings-hover {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    width: fit-content;
    height: fit-content;
    z-index: 2000;
}

.location-settings-hover-item {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    background-color: var(--accent-one);
    color: var(--text-color-one);
    box-shadow: 0px 2px 20px rgba(0,0,0, .5);
    border-radius: 5px;
    cursor: pointer;
}

.file-name-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: fit-content;
    max-width: calc(100%, 70px);
    overflow: auto;
    text-wrap: nowrap;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: inherit;
    font-size: var(--font-size-one);
    box-sizing: border-box;
}

.zip-download-wrapper {
    position: absolute;
    top: 0px;
    right: 20px;
    width: fit-content;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
}

.zip-download-container {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.zip-download-container:hover {
    background-color: rgba(110, 110, 110, .3);
}

.custom-programming-exam-wrapper {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    position: relative;
}

.top-right-custom-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    color: var(--almost-black);
}

.re-run-autograder, .top-right-item {
    background-color: var(--accent-one);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width: 110px;
    height: 35px;
    font-size: var(--font-size-one);
    color: var(--text-color-one);
    cursor: pointer;
    user-select: none;
}

.top-right-item:hover {
    background-color: var(--accent-two);
}

.custom-exam-wrapper-view {
    width: 100%;
    max-width: 800px;
    height: fit-content;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--gray-two);
    border: 1px solid var(--gray-five);
    color: var(--almost-black);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 20px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.true-false-question-view {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--gray-five);
    padding: 10px;
    background-color: var(--gray-one);
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}

.true-false-top {
    padding: 5px;
    height: fit-content;
    width: 100%;
    text-align: left;
    font-size: var(--font-size-one);
}

.question-number {
    width: 100%;
    height: fit-content;
    padding: 5px;
    height: 30px;
    font-size: var(--font-size-one);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.true-false-answer-block {
    padding: 5px;
    width: 100%;
    height: fit-content;
    display: flex;
    column-gap: 10px;
    font-size: var(--font-size-one);
    justify-content: flex-start;
    align-items: center;
}

.bubble-with-text-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.bubble {
    border-radius: 19px;
    background-color: var(--text-color-one);
    border: 1px solid var(--gray-five);
    box-sizing: border-box;
    min-height: 19px;
    min-width: 19px;
    cursor: pointer;
}

.bubble.bubbled {
    background-color: var(--accent-one);
    border: none;
}

.bubble.incorrect {
    background-color: var(--incorrect);
    border: none;
}

.bubble.correct {
    background-color: var(--correct);
    border: none;
}

.bubble.correct-but-not-selected {
    background-color: var(--correct);
    opacity: var(--opacity-level);
    border: none;
}

.select-all-that-apply-array {
    padding: 5px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: var(--font-size-one);
    justify-content: center;
    align-items: flex-start;
}

.square {
    border-radius: 3px;
    background-color: var(--text-color-one);
    border: 1px solid var(--gray-five);
    box-sizing: border-box;
    min-height: 18px;
    min-width: 18px;
    cursor: pointer;
}

.square.bubbled {
    background-color: var(--accent-one);
    border: none;
}

.square.incorrect {
    background-color: var(--incorrect);
    border: none;
}

.square.correct {
    background-color: var(--correct);
    border: none;
}

.square.correct-but-not-selected {
    background-color: var(--correct);
    opacity: var(--opacity-level);
    border: none;
}

.square-with-text-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.edit-select {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.text-response-area {
    padding: 5px;
    width: 100%;
    height: fit-content;
}

.text-response-area-input {
    padding: 5px;
    background-color: var(--text-color-one);
    border-radius: 5px;
    width: 100%;
    height: fit-content;
    min-height: 175px;
    border: 1px solid var(--gray-five);
    outline: none;
    font-size: var(--font-size-one);
    color: var(--almost-black);
}

.code-response-area {
    border-radius: 5px;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--gray-five);
    overflow: hidden;
}

.code-response-area-wrapper {
    width: 100%;
    padding: 5px;
    height: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.code-editor-header {
    width: 100%;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2px;
}

.pts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.select-edit, .grading-box-question {
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 2px solid var(--gray-five);
    width: 40px;
    height: 18px;
    text-align: center;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    padding-bottom: -2px;
}

.select-edit:focus , .grading-box-question:focus {
    border-bottom: 2px solid var(--accent-one);
}

.select-edit {
    text-align: left;
    width: 100%;
}

.no-questions {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
}

.trash-question {
    cursor: pointer;
}

.grid-number-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    color: inherit;
}

.add-option {
    cursor: pointer;
    color: inherit;
}

.container-zip-location {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
    width: 100%;
    padding: 5px;
    font-size: var(--font-size-one);
    color: inherit
}

.message-container {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    row-gap: 10px;
}

.message-container.code-response {
    height: 31px;
}

.draggable-question {
    width: 100%;
    height: 100%;
}

.check-auto {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
}

.bank-questions-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.bank-of-qs-option {
    border-radius: 5px;
    padding-left: 5px;
    width: 30px;
    padding-right: 5px;
    font-size: var(--font-size-two);
    color: var(--almost-black);
    border: 1px solid var(--gray-five);
    background-color: var(--text-color-one);
    box-sizing: border-box;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bank-of-qs-option.current {
    border: none;
    background-color: var(--accent-one);
    color: var(--text-color-one)
}

.grading-pdf-question {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
}

.upload-delete-pdf-question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    flex-wrap: nowrap;
    padding: 5px;
    font-size: var(--font-size-one);
}

.points {
    font-size: var(--font-size-two);
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.test-option {
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
}

.selected-tests {
    padding: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    padding-left: 10px;
    width: 100%;
}

.selected-test {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-one);
    color: var(--almost-black);
    column-gap: 10px;
    width: 100%;
}

.container-for-test-config {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 10px;
}

.question-tests-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 15px;
    flex-direction: column;
    font-size: var(--font-size-one);
    color: var(--almost-black);
    overflow: auto;
}

.a-single-test {
    width: 100%;
    height: auto;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-one);
    color: var(--almost-black);
    font-weight: 600;
    box-sizing: border-box;
    border: 1px solid var(--gray-four);
    background-color: var(--gray-one-half);
    border-radius: 5px;
    padding: 10px;
    word-break: break-all;
    column-gap: 15px;
}

.left-of-test {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.right-of-test {
    text-wrap: nowrap;
}

.if-output-container {
    flex-direction: column;
    display: flex;
    width: 100%;
}

.bottom-output {
    padding: 5px;
    text-align: left;
    font-size: var(--font-size-one);
    background-color: var(--gray-half);
    border: 1px solid var(--gray-four);
    border-top: none;
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: auto;
}

.stop-overflow-compilation {
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: auto;
}

.tests-button {
    color: var(--almost-black);
    background-color: var(--gray-mail);
    border: 1px solid var(--gray-four);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    border-radius: 3px;
    font-size: var(--font-size-one);
}

.x {
    position: absolute;
    top: 5px;
    right: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.label-test {
    color: var(--almost-black);
    font-size: var(--font-size-one);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.test-label-ind {
    overflow: auto;
    text-wrap: nowrap;
    width: 100%;
    max-width: 420px;
}

.add-a-question {
    border: 1px solid var(--gray-five);
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0,0,0,.5);
}

.clear {
    margin-top: 10px;
}

.run-tests {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.to-docs-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
}

.docs-wrapper-icon {
    border-radius: 20px;
    border: 1px solid var(--almost-black);
    cursor: pointer;
    max-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.autograder-container {
    width: 100%;
    height: 100%;
    padding: 15px;
    padding-left: 75px;
    padding-top: 75px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.autograder-documentation {
    width: 100%;
    max-width: 800px;
    height: fit-content;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--gray-two);
    border: 1px solid var(--gray-five);
    color: var(--almost-black);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 20px;
    white-space: normal; 
    font-size: var(--font-size-one);
    overflow: auto;
}

.autograder-documentation pre {
    background-color: var(--gray-half); /* Light grey background */
    border: 1px solid #ddd;    /* Light border */
    border-radius: 4px;        /* Rounded corners */
    padding: 10px;             /* Padding */
}

.autograder-documentation code {
    background-color: var(--gray-half); /* Light grey background */
    border-radius: 3px;        /* Rounded corners */
    padding: 2px 4px;          /* Padding */
    font-family: monospace;    /* Monospace font */
    color: var(--almost-black)
}

.tests-container-parent {
    width: 100%;
    height: 100%;
}

.start-a-new-test {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    z-index: 1001;
}

.input-wrapper-test-zip {
    border: 2px solid var(--accent-one);
    background-color: var(--gray-two);
    height: 50px;
    width: 260px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 5px;
    font-size: var(--font-size-one);
    color: var(--almost-black);
    padding-left: 20px;
    padding-right: 20px;
    column-gap: 10px;
}

.clear-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.autograde-answer-string {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
    font-size: var(--font-size-one);
    color: var(--almost-black);    
    text-wrap: nowrap;
}

.auto-answer-input {
    background-color: var(--text-color-one);
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--gray-five);
    outline: none;
    font-size: var(--font-size-one);
    color: var(--almost-black);
}

.sub-del {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
}

.remove {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

.settings-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;    
    overflow: auto;
    background-color: var(--gray-two);
}

.middle-content {
    background-color: var(--text-color-one);
    width: 100%;
    height: 100vh;
    max-width: 700px;    
    padding: 15px;
    display: flex;
    row-gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    row-gap: 17px;
    border: "none";
    position: "relative";
    border-left: 1px solid var(--gray-five);
    border-right: 1px solid var(--gray-five);
}

.complete-on-scan {
    color: var(--almost-black);
    font-size: var(--font-size-one);
}

.support-container {
    margin-top: 20px;
    width: 100%;
    max-width: 700px;
    height: auto;
    row-gap: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.send {
    color: white;
    background-color: var(--accent-one);
    border-radius: 5px;
    cursor: pointer;
    width: 160px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-one);
}

.support-item {
    width: 100%;
    max-width: 700px;
    padding: 10px;
    outline: none;
    border: 1px solid var(--gray-mail);
    border-radius: 5px;
    font-size: var(--font-size-one);
    background-color: var(--gray-one);
}

.pp-and-tac {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    font-size: var(--font-size-one);
}

.item-of-tac {
    font-size: var(--font-size-one);
    cursor: pointer;
    color: var(--accent-one);
}

.item-of-tac:hover {
    text-decoration: underline;
}

.signup-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--accent-one);
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-button {
    color: rgb(100,100,100);
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-button.hovered:hover {
    color: var(--accent-one)
}

.back-absolute {
    position: absolute;
    left: 12.5px;
    top: 73.5px;
    display: flex;
    column-gap: 0px;
    justify-content: flex-start;
    align-items: center;
    color: var(--almost-black);
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
}

.back-button.hovered:hover {
    color: var(--accent-one)
}

.back-absolute:hover {
    color: var(--accent-one);
    text-decoration: underline;
}


.no-button-css {
    outline: none;
    border: none;
    background-color: transparent;
    color: var(--text-color-one)
}

.tippy-box[data-theme~='custom-tooltip'] {
    background-color: #333; /* Background color */
    color: #fff; /* Text color */
    font-size: 12px; /* Font size */
    border-radius: 4px; /* Rounded corners */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); /* Shadow */
}
  
.tippy-arrow {
    color: #333; /* Match the tooltip background color */
}

.outside-click {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
}

.outside-click-side-nav {
    position: fixed;
    width: 60px;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
}

.pdf-docs-wrapper-icon {
    position: absolute;
    top: 3px;    
}

.answer-string {
    font-size: var(--font-size-one);
    color: var(--almost-black)
}

.regrade {
    height: 100%;
    color: var(--almost-black);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pdf-tippy-plus-upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.answer-normal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    text-wrap: nowrap;
}

.container-for-tippy-lock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.message {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-right: 35px;
    margin-left: 10px;
    border-radius: 5px;
    border: 2px solid var(--gray-four);
    margin-bottom: 50px;
    background-color: var(--gray-one);
    max-width: 320px;
    font-size: var(--font-size-one);
    column-gap: 10px;
    position: relative;
}

.reload {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(calc(100% + 10px), -50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reload:hover {
    color: var(--accent-one);
}

.attempt {
    font-size: var(--font-size-two);
    padding-left: 2px;
}

.single-q-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.not-found-sq {
    width: 100%;
    font-size: var(--font-size-one);
    column-gap: 10px;
    border-radius: 5px;
    border: 1px solid var(--gray-five);
    padding: 10px;
    background-color: var(--gray-one);
    display: flex;
    justify-content: center;
    align-items: center;
}

.a-single-test-edit {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    column-gap: 10px;
    font-size: var(--font-size-two);
    color: var(--almost-black);
    font-weight: 700;
    text-wrap: nowrap;
    flex-wrap: wrap;
    row-gap: 15px;
}

.lambda-input {
    border: none;
    border-radius: 5px;
    background-color: var(--text-color-one);
    border: 1px solid var(--gray-five);
    padding: 3px;
    font-size: var(--font-size-one);
}

.lambda-input::placeholder {
    font-style: italic;
}

.sub-sec-lambda-container {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
    flex-wrap: nowrap;
}

.tests-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 20px;
}

.trash-question-test {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    cursor: pointer;
}

.lambda-results-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    row-gap: 15px;
}

.single-test-wrapper {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 10px;
}

.test-text-area {
    border-radius: 10px;
    width: 100%;
    outline: none;
    border: 1px solid var(--gray-five);
    min-height: 40px;
    padding: 5px;
}

.test-text-input {
    border-radius: 10px;
    width: 100%;
    outline: none;
    border: 1px solid var(--gray-five);
    padding: 5px;
}

.test-text-area::placeholder, .test-text-input::placeholder {
    font-style: italic;
}

.wrap-lambdas-tests {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.run-new {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
    flex-direction: column;
}

.tests-center {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullscreen-editor-side-bar {
    position: fixed;
    width: calc(100vw - var(--nav-scale));
    height: calc(100vh - var(--nav-scale));
    top: var(--nav-scale);
    left: var(--nav-scale);
    z-index: 1000;
}

.re-run-autograder {
    width: 30px;
    height: 30px;
}

.svg-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--accent-one);
    padding: 3px;
    color: var(--accent-one);
    border-radius: 25px;
    background-color: var(--gray-two);
}

.absolute-reload {
    position: fixed;
    z-index: 10000;
    top: 30px;
    right: 180px;
    transform: translate(0%, -50%);
}

.show-prev {
    color: var(--accent-one);
    text-decoration: underline;
    font-size: var(--font-size-one);
    cursor: pointer;
}

.show-prev:hover {
    color: var(--accent-two);
}

.apply-remove {
    background-color: var(--accent-one);
    border-radius: 30px;
    padding-left: 7.5px;
    padding-right: 8px;
    color: var(--text-color-one);
    font-size: var(--font-size-one);
    padding-bottom: 2px;
    box-sizing: border-box;
    cursor: pointer;
}

.apply-remove:hover {
    background-color: var(--accent-two);
}

.apply-remove.remove {
    cursor: default;
}

.apply-remove.remove:hover {
    background-color: var(--accent-one);
}

.key-value {
    font-size: var(--font-size-one);
    display: flex;
    column-gap: 10px;
    display: flex;
    align-items: center;
}

.docker-container {
    display: flex;
    row-gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background-color: var(--gray-one);
    border-radius: 3px;
    border: 2px solid var(--gray-four);
    font-size: var(--font-size-two);
}

.dockerfile {
    text-decoration: underline;
    cursor: pointer;
}

.dockerfile:hover {
    color: var(--accent-one)
}

.key-value-logs {
    font-size: var(--font-size-one);
    display: flex;
    column-gap: 10px;
    display: flex;
    align-items: center;
    text-wrap: wrap;
    word-break: break-word;
}

.dockerfile-container-center {
    display: flex;
    width: calc(100vw - var(--nav-scale));
    height: calc(100vh - var(--nav-scale));
    justify-content: center;
    align-items: flex-start;
    background-color: var(--gray-two);
}

.dockerfile-container-second {
    width: fit-content;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    background-color: var(--text-color-one);
    border-left: 1px solid var(--gray-five);
    border-right: 1px solid var(--gray-five);
    flex-direction: column;
    row-gap: 15px;
    box-sizing: border-box;
}

.loader {
    width: 20px;
    height: 20px;
    border: 2px solid var(--accent-one);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-white {
    width: 20px;
    height: 20px;
    border: 2px solid var(--text-color-one);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.container-govaluate {
    background-color: var(--gray-one);
    border-radius: 3px;
    border: 2px solid var(--gray-four);
}

.attempt-processing-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
}

.error-wrapper-container {
    padding: 15px;
    height: calc(100vh - 160px);
    width: calc(100vw - 100px);
}

.error-wrapper-container-full {
    padding: 15px;
    height: calc(100vh - var(--nav-scale));
    width: 100%;
}

.error-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    font-size: var(--font-size-one);
    background-color: var(--gray-one);
    border-radius: 3px;
    border: 2px solid var(--gray-four);
    padding: 10px;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow: auto;
}

.chart-wrapper {
    border: 2px solid black;
}

.no-scroll {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.no-scroll::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and newer Edge */
}

.view-docs {
    color: var(--accent-one);
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    flex-wrap: nowrap;
    height: 100%;
}

.view-docs:hover {
    color: var(--accent-two);
}

input[type="checkbox"] {
    cursor: pointer;
}

.cont-attempt-extra {
    font-size: var(--font-size-one);
    display: flex;
    flex-wrap: nowrap;
    column-gap: 10px;
    align-items: center;
    justify-content: flex-start;
}

.students-upload {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    font-size: var(--font-size-one);
}

.location {
    font-size: var(--font-size-one);
    padding-left: 8px;
    max-width: calc(100% - 90px);
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis;
}